import { catchError } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { APP_CONFIG } from '@app/shared/models/constants';
import { ClientAccountCommand } from '@app/account/containers/profile-v2/models/client-account.command';
import { HttpClient } from '@angular/common/http';
import { GingrAccountCommand } from '@app/account/containers/profile-v2/models/gingr-account.command';
import { CurrentUser } from '@app/shared/models/current-user';
import { ClientPersonalDetails } from '@app/account/containers/profile-v2/models/client-personal-details';
import { GingrPersonalDetails } from '@app/account/containers/profile-v2/models/gingr-personal-details';
import { GingrExtraDetailsCommand } from '@app/account/containers/profile-v2/models/gingr-extra-details.command';
import { GingrPricingCommand } from '@app/account/containers/profile-v2/models/gingr-pricing.command';
import { GingrAvailabilityCommand } from '@app/account/containers/profile-v2/models/gingr-availability.command';
import { GingrServicesCommand } from '@app/account/containers/profile-v2/models/gingr-services.command';
import { BlockedCountriesCommand } from '@app/account/containers/settings-v2/models/blocked-countries.command';
import { UserBankModel } from '@app/wallet/models/user-bank.model';
import { EscortClientPreferences } from '@app/shared/models/escort-client-preferences';
import { BasicUserDetails } from '@app/account/models/basic-user-details';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private readonly config = inject(APP_CONFIG);
  private readonly http = inject(HttpClient);

  getBasicUserDetails(userId: number) {
    return this.http
      .get<BasicUserDetails>(`${this.config.apiV3}/user/basic/${userId}`)
      .pipe(catchError(() => of(null)));
  }

  updateAccountLanguage(language: number): Observable<any> {
    return this.http.post(
      `${this.config.apiV3}/user/account/language/${language}`,
      {}
    );
  }

  getUserBank(): Observable<UserBankModel> {
    return this.http
      .get<UserBankModel>(`${this.config.apiV3}/user/account/bank`)
      .pipe(catchError(() => of(null)));
  }

  setUserBank(data: UserBankModel): Observable<UserBankModel> {
    return this.http.post<UserBankModel>(
      `${this.config.apiV3}/user/account/bank`,
      data
    );
  }

  disableMode(disable: boolean): Observable<any> {
    return this.http.post(
      `${this.config.apiV3}/user/account/disable/${disable}`,
      {}
    );
  }

  setUserAccountClient(data: ClientAccountCommand): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(
      `${this.config.apiV3}/user/account/client`,
      data
    );
  }

  setBlockedCountries(data: BlockedCountriesCommand): Observable<any> {
    return this.http.post(
      `${this.config.apiV3}/user/account/blocked-countries`,
      data
    );
  }

  setUserAccountGingr(data: GingrAccountCommand): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(
      `${this.config.apiV3}/user/account/gingr`,
      data
    );
  }

  setUserPersonalDetailsGingr(
    data: GingrPersonalDetails
  ): Observable<CurrentUser> {
    return this.http
      .post<CurrentUser>(
        `${this.config.apiV3}/user/account/gingr-personal-details`,
        data
      )
      .pipe(catchError(() => of(null)));
  }

  setUserPersonalDetailsClient(
    data: ClientPersonalDetails
  ): Observable<CurrentUser> {
    return this.http
      .post<CurrentUser>(
        `${this.config.apiV3}/user/account/client-personal-details`,
        data
      )
      .pipe(catchError(() => of(null)));
  }

  setExtraDetailsGingr(
    data: GingrExtraDetailsCommand
  ): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(
      `${this.config.apiV3}/user/account/gingr/extra`,
      data
    );
  }

  setPricing(data: GingrPricingCommand): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(
      `${this.config.apiV3}/user/account/pricing`,
      data
    );
  }

  setAvailability(data: GingrAvailabilityCommand): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(
      `${this.config.apiV3}/user/account/availability`,
      data
    );
  }

  setServices(data: GingrServicesCommand): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(
      `${this.config.apiV3}/user/account/services`,
      data
    );
  }

  goLive(): Observable<CurrentUser> {
    return this.http.post<CurrentUser>(
      `${this.config.apiV3}/user/account/goLive`,
      {}
    );
  }

  setEscortClientPreferences(preferences: EscortClientPreferences) {
    return this.http.patch<EscortClientPreferences>(
      `${this.config.apiV3}/user/preference/escort`,
      preferences
    );
  }

  getEscortPreferences(): Observable<EscortClientPreferences> {
    return this.http
      .get<EscortClientPreferences>(
        `${this.config.apiV3}/user/preference/escort`
      )
      .pipe(catchError(() => of(null)));
  }
}
